* {
  text-align: center;
  background-color: antiquewhite;
  background-size: cover;
}
.logo {
  font-weight: bold;
  font-size: x-large;
  color: rgb(142, 18, 18);
  padding-left: 40px;
  padding-top: 5px;
}
/* .app {
  background-color: antiquewhite;
} */
/* body {background-color: antiquewhite;} */
/* html {  
   height: 100%;
} */
/* .hero {
  
} */
/* .hero {
  display: flex;
  flex-wrap: wrap;
  float: left;
} */
.title   {  
  display: flex;
  
  flex-direction: row;
  
  /* flex-direction: column; */
  /* max-width: 850px; */
  margin: 60px !important;
  /* text-align: left !important; */
  border-bottom: solid 1px #333;
  /* padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #4e87c0;
  border-radius: .3rem; */
}
.profilepic {
  border: solid 10px rgb(142, 18, 18);
  border-radius: 90%;
  margin-bottom: 10px;
  min-width: 300px;
  max-height: 400px;
}

.headtext h1, .headtext h2, p {
  /* display: flex;
  flex-wrap: wrap; */
  
  text-align: left;
  /* min-width: 100%; */
}
@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }
}
.skills-title h2{
  text-align: center !important;
} 
.title h1, h2, p {
  padding: 10px;
}

.skills{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
}

.languages {
  border: solid 5px rgb(142, 18, 18);
  max-width: 200px;
  
}  
.frameworks {
  border: solid 5px rgb(142, 18, 18);
  max-width: 200px;
}
.tools {
  border: solid 5px rgb(142, 18, 18);
  max-width: 200px;
}
/* body { */
  /* max-width: 1200px; */
  /* margin: 0 auto; */
/* } */
.carousel { 
  width:500px;
  height:300px;
  margin: auto;
  
}
.carousel-caption, h3, .carousel-indicators {
  background: none;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  background: none;
}
.carousel-indicators {
  margin: 0px 25px 25px !important;
}
/* #teal-job-tracker-iframe-stable{
  display: none !important;
} */
/* .role */
/* .app-footer {  
  margin-top: 5px;
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  height: auto;
} */
